/* eslint-disable react-refresh/only-export-components */
import { lazy } from "react";

import PagesRootLayout from "common/root-layouts/PagesRootLayout";
import RootLayoutCover from "common/root-layouts/RootLayoutCover";
import AuthUserRoot from "common/root-layouts/AuthUserRoot";
import NotFound from "pages/not-found";
import TraineesRoot from "pages/user-pages/trainees/TraineesRoot";

const Home = lazy(() => import("pages/home"));
const Venues = lazy(() => import("pages/venues"));
const VenueDetails = lazy(() => import("pages/venue-details"));
const BookCourt = lazy(() => import("pages/book-court"));
const BookingQuery = lazy(() => import("pages/booking-query"));
// const BookingQueryOther = lazy(() => import("pages/booking-query-other"));
const CourtBooking = lazy(() => import("pages/court-booking"));
// const Training = lazy(() => import("pages/training-old"));
const Training = lazy(() => import("pages/training"));
const BuyTraningIndex = lazy(() => import("pages/training/BuyTraningIndex"));
const Pricing = lazy(() => import("pages/pricing"));
const BuyPlan = lazy(() => import("pages/membership"));
const BlogsPage = lazy(() => import("pages/blogs"));
const BlogDetailPage = lazy(() => import("pages/blog-detail"));
const AboutUs = lazy(() => import("pages/about-us"));
const ContactUs = lazy(() => import("pages/contact-us"));
const PrivacyPolicy = lazy(() => import("pages/privacy-policy"));
const TermsConditions = lazy(() => import("pages/terms-condition"));
const FAQs = lazy(() => import("pages/faqs"));
// authentication
const LogIn = lazy(() => import("pages/authentication/login"));
const SignUp = lazy(() => import("pages/authentication/signup"));
const VerifyOTP = lazy(() => import("pages/authentication/verify-otp"));
const ForgotPassword = lazy(() => import("pages/authentication/forgot-password"));
const ResetPassword = lazy(() => import("pages/authentication/reset-password"));
// user pages
const Dashboard = lazy(() => import("pages/user-pages/dashboard"));
const MyBookings = lazy(() => import("pages/user-pages/my-bookings"));
// const Invoice = lazy(() => import("pages/user-pages/invoice"));
const Profile = lazy(() => import("pages/user-pages/profile"));
const Trainees = lazy(() => import("pages/user-pages/trainees"));
const TraineeForm = lazy(() => import("pages/user-pages/trainees/TraineeForm"));
const ProfileSettings = lazy(() => import("pages/user-pages/profile-settings"));

const routes = [
  {
    path: "/",
    element: <PagesRootLayout />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: 'venues',
        children: [
          {
            index: true,
            element: <Venues />,
          },
          {
            path: ':venueId',
            element: <VenueDetails />,
          },
        ]
      },
      {
        path: 'book-court',
        element: <BookCourt />,
      },
      {
        path: 'booking-query',
        element: <BookingQuery />
      },
      // {
      //   path: 'pickleball-booking-query',
      //   element: <BookingQueryOther option={1} />
      // },
      // {
      //   path: 'table-tennis-booking-query',
      //   element: <BookingQueryOther option={2} />
      // },
      {
        path: 'membership',
        children: [
          {
            index: true,
            element: <Pricing />,
          },
          {
            path: ':planId',
            element: <BuyPlan />,
          },
        ]
      },
      {
        path: 'private-team-lessons',
        children: [
          {
            index: true,
            element: <Training />,
          },
          {
            path: ':planId',
            element: <BuyTraningIndex />,
          },
        ]
      },
      {
        path: 'blogs',
        children: [
          {
            index: true,
            element: <BlogsPage />,
          },
          {
            path: ':blogId',
            element: <BlogDetailPage />,
          },
        ]
      },
      // {
      //   path: 'training-dev',
      //   element: <Training />
      // },
      {
        path: 'about-us',
        element: <AboutUs />
      },
      {
        path: 'contact-us',
        element: <ContactUs />
      },
      {
        path: 'faqs',
        element: <FAQs />
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />
      },
      {
        path: 'terms-condition',
        element: <TermsConditions />
      },
    ],
  },
  {
    path: "",
    element: <RootLayoutCover />,
    errorElement: <NotFound />,
    children: [
      {
        path: 'login',
        element: <LogIn />
      },
      {
        path: 'sign-up',
        element: <SignUp />
      },
      {
        path: 'verify-otp',
        element: <VerifyOTP />
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />
      },
      {
        path: 'reset-password',
        element: <ResetPassword />
      },
      {
        path: 'book-court/:courtId',
        element: <CourtBooking />
      }
    ]
  },
  {
    path: "",
    element: <AuthUserRoot />,
    errorElement: <NotFound />,
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />
      },
      {
        path: 'my-bookings',
        element: <MyBookings />
      },
      // {
      //   path: 'invoice',
      //   element: <Invoice />
      // },
      {
        path: 'profile',
        element: <Profile />
      },
      {
        path: 'trainees',
        element: <TraineesRoot />,
        children: [
          {
            index: true,
            element: <Trainees />
          },
          {
            path: 'add-trainee',
            element: <TraineeForm />
          },
          {
            path: 'edit-trainee/:id',
            element: <TraineeForm />
          },
        ]
      },
      {
        path: 'profile-settings',
        element: <ProfileSettings />
      },
    ]
  },
];

export default routes;
