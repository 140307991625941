import { appendZero } from "helper";
import dashboardIcon from 'assets/icons/dashboard-icon.svg';
import bookingIcon from 'assets/icons/booking-icon.svg';
// import invoiceIcon from 'assets/icons/invoice-icon.svg';
import profileIcon from 'assets/icons/profile-icon.svg';
import settingsIcon from 'assets/icons/settings-icon.svg';
import traineeIcon from 'assets/icons/trainee-icon.svg';
import dayjs from "dayjs";
import levelOne from "assets/icons/level-1.svg";
import levelTwo from "assets/icons/level-2.svg";
import levelThree from "assets/icons/level-3.svg";

export const mainNavLinks = [
  {
    id: "home",
    href: "/",
    label: "Home"
  },
  {
    id: "venues",
    href: "/venues",
    label: "Venues"
  },
  {
    id: "book-court",
    href: "/book-court",
    label: "Book a Court"
  },
  {
    id: "private-team-lessons",
    href: "/private-team-lessons",
    label: "Training"
  },
  // {
  //   id: "pickleball-booking-query",
  //   href: "/pickleball-booking-query",
  //   label: "Pickleball"
  // },
  // {
  //   id: "table-tennis-booking-query",
  //   href: "/table-tennis-booking-query",
  //   label: "Table tennis"
  // },
  {
    id: "membership",
    href: "/membership",
    label: "Membership"
  },
  {
    id: "blogs",
    href: "/blogs",
    label: "Blogs"
  },
  {
    id: "about-us",
    href: "/about-us",
    label: "About Us"
  },
  {
    id: "contact-us",
    href: "/contact-us",
    label: "Contact Us"
  },
];

export const mainNavMobileLinks = [
  {
    id: "home",
    href: "/",
    label: "Home"
  },
  {
    id: "venues",
    href: "/venues",
    label: "Venues"
  },
  {
    id: "book-court",
    href: "/book-court",
    label: "Book a Court"
  },
  {
    id: "private-team-lessons",
    href: "/private-team-lessons",
    label: "Training"
  },
  // {
  //   id: "pickleball-booking-query",
  //   href: "/pickleball-booking-query",
  //   label: "Pickleball"
  // },
  // {
  //   id: "table-tennis-booking-query",
  //   href: "/table-tennis-booking-query",
  //   label: "Table tennis"
  // },
  {
    id: "membership",
    href: "/membership",
    label: "Membership"
  },
  {
    id: "blogs",
    href: "/blogs",
    label: "Blogs"
  },
  {
    id: "about-us",
    href: "/about-us",
    label: "About Us"
  },
  {
    id: "contact-us",
    href: "/contact-us",
    label: "Contact Us"
  },
  {
    id: "sign-up",
    href: "/sign-up",
    label: "Sign Up"
  },
  {
    id: "signin",
    href: "/login",
    label: "Sign In"
  },
];

export const footerData = {
  quickLinks: [
    {
      href: "/",
      label: "Home",
    },
    {
      href: "/venues",
      label: "Venues",
    },
    {
      href: "/book-court",
      label: "Book a Court",
    },
    {
      href: "/private-team-lessons",
      label: "Training"
    },
    {
      href: "/membership",
      label: "Membership"
    },
    {
      href: "/about-us",
      label: "About Us",
    },

  ],
  supportLinks: [
    {
      href: "/contact-us",
      label: "Contact Us",
    },
    {
      href: "/faqs",
      label: "FAQ's",
    },
    {
      href: "/privacy-policy",
      label: "Privacy Policy",
    },
    {
      href: "/terms-condition",
      label: "Terms & Conditions",
    },
  ],
  otherLinks: [
    {
      href: "/login",
      label: "Sign In",
    },
    {
      href: "/sign-up",
      label: "Sign Up",
    },
    {
      href: "/booking-query",
      label: "Booking Query",
    },
    // {
    //   href: "/pickleball-booking-query",
    //   label: "Pickleball"
    // },
    // {
    //   href: "/table-tennis-booking-query",
    //   label: "Table tennis"
    // },
    {
      href: "/blogs",
      label: "Blogs"
    },
  ],
  otherAuthLinks: [
    {
      href: "/dashboard",
      label: "Dashboard",
    },
    {
      href: "/my-bookings?type=0&tab=0",
      label: "My Bookings",
    },
    {
      href: "/profile",
      label: "My Profile",
    },
    {
      href: "/booking-query",
      label: "Booking Query",
    },
    // {
    //   href: "/pickleball-booking-query",
    //   label: "Pickleball"
    // },
    // {
    //   href: "/table-tennis-booking-query",
    //   label: "Table tennis"
    // },
    {
      href: "/blogs",
      label: "Blogs"
    },
  ],
}

export const taxData = {
  tax: 13,
  taxMassage: `Taxes & fees include of 13% HST.`,
}

export const clientContactData = {
  phoneNo: {
    href: "tel:+19059604881",
    label: "+1 905-960-4881",
  },
  email: {
    href: "mailto:hello@royalbadmintonclub.com",
    label: "hello@royalbadmintonclub.com",
  },
  email_2: {
    href: "mailto:info@royalbadmintonclub.com",
    label: "info@royalbadmintonclub.com",
  },
  address: <>40 Holtby Ave, Unit 6, Brampton, <br />ON, L6X 2M1</>,
  addressStr: "40 Holtby Ave, Unit 6, Brampton, ON, L6X 2M1",
  social: {
    facebook: "https://www.facebook.com/royalbadmintoncanada",
    instagram: "https://www.instagram.com/royalbadmintonclub",
    twitter: "https://twitter.com/royalclubcanada",
    youtube: "https://www.youtube.com/@RoyalBadmintonClub",
  }
}

export const userNavData = [
  {
    id: "dashboard",
    href: "/dashboard",
    label: "Dashboard",
    icon: dashboardIcon,
  },
  {
    id: 'my-bookings',
    href: "/my-bookings?type=0&tab=0",
    label: "My Bookings",
    icon: bookingIcon,
  },
  // {
  //   id: 'invoice',
  //   href: "/invoice",
  //   label: "Invoice",
  //   icon: invoiceIcon,
  // },
  {
    id: 'profile',
    href: "/profile",
    label: "Profile",
    icon: profileIcon,
  },
  {
    id: 'trainees',
    href: "/trainees",
    label: "Trainees",
    icon: traineeIcon,
  },
  {
    id: 'profile-settings',
    href: "/profile-settings",
    label: "Settings",
    icon: settingsIcon,
  },
];

export const profileSettingsNavData = [
  {
    key: "edit-profile",
    label: "Edit Profile",
  },
  {
    key: "change-password",
    label: "Change Password",
  },
];

export const bookingsNavData = [
  {
    key: "upcoming",
    label: "Upcoming",
  },
  {
    key: "completed",
    label: "Completed",
  },
  {
    key: "on-going",
    label: "On Going",
  },
  {
    key: "cancelled",
    label: "Cancelled",
  },
];

export const expertiseData = [
  {
    value: 1,
    label: "Beginner",
  },
  {
    value: 2,
    label: "Intermediate",
  },
  {
    value: 3,
    label: "Master",
  },
];

export const traineeTypeData = [
  {
    value: 2,
    label: "Child (4 to 16)",
  },
  {
    value: 1,
    label: "Adult (16+ to 80)",
  },
];

export const enrolledForData = [
  {
    value: 1,
    label: 'Court Rental',
  },
  {
    value: 2,
    label: 'Coaching',
  },
];

export const dobMaxDate = dayjs().subtract(7, 'year');
export const dobMaxDateForChild = dayjs().subtract(4, 'year');
export const dobMinDateForChild = dayjs().subtract(16, 'year');
export const dobMaxDateForAdult = dayjs().subtract(16, 'year').subtract(1, 'day');
export const dobMinDateForAdult = dobMaxDateForAdult.subtract(80 - 16, 'year');

export function generateTimeArray() {
  const timeArray = [];
  let idCounter = 1;

  for (let hour = 6; hour <= 23; hour++) {

    timeArray.push({
      id: idCounter,
      value: hour,
      label: `${hour <= 12 ? appendZero(hour) : appendZero(hour - 12)}:00 ${hour < 12 ? 'AM' : 'PM'}`,
    });

    idCounter++;
  }

  return timeArray;
}

export const planLevelIcons = {
  1: levelOne,
  2: levelTwo,
  3: levelThree,
}


export const specialDates = [
  '2024-05-20'
]

export const coachLevelArrey = [
  {
    value: 1,
    label: "Pro",
  },
  {
    value: 2,
    label: "Master",
  },
  {
    value: 3,
    label: "Champ",
  },
];