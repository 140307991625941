import { Box, IconButton, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import poster from "assets/images/summer-camp-2025.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "calc(100% - 32px)",
  height: "auto",
  bgcolor: "transparent",
  boxShadow: "none",
  p: 0,
  outline: "none !important",
};

const SessionDilog = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const popupClosed = sessionStorage.getItem("popupClosed");
    if (!popupClosed) {
      const timer = setTimeout(() => {
        setOpen(true);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    sessionStorage.setItem("popupClosed", "true");
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <>
        <IconButton size="small" sx={{ position: 'absolute', top: 10, right: 10, bgcolor: '#fff !important' }} onClick={handleClose}>
          <CloseRoundedIcon sx={{ color: '#222' }} />
        </IconButton>
        <Box sx={style}>
          <a href="https://api.whatsapp.com/send?phone=19059604881&text=Hello" target="_blank" rel="noreferrer">
            <Box className="img-fluid" component={"img"} src={poster} alt="Royal Badminton Club Summer Camp 2025" />
          </a>
        </Box>
      </>
    </Modal>
  );
};

export default SessionDilog;
