/* eslint-disable no-undef */
import dayjs from "dayjs";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import pxToRem from "theme/functions/pxToRem";
import AWS from "aws-sdk";
import moment from "moment";
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

function isValidImageUrl(url, callback) {
  if (url) {
    const img = new Image();
  
    img.onload = () => callback(true); // Call the callback with true if image loads
    img.onerror = () => callback(false); // Call the callback with false if image fails
  
  } else {
    callback(false)
  }
}

const isColorDark = (hexColor) => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance < 0.6;
};

const hashCode = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  return hash;
};

const generateHexColor = (str) => {
  const colorValue = Math.abs(hashCode(str)) % 16777215; // 16777215 is FFFFFF in hex

  const hexColor = "#" + colorValue.toString(16).padStart(6, "0");

  const isDarkColor = isColorDark(hexColor);

  const fontColor = isDarkColor ? "#fff" : "#000";

  return [hexColor, fontColor];
};

export const stringAvatar = (name, image, size = 40, font = 17, sx = {}) => {
  const [bgcolor, color] = generateHexColor(name);
  let avatarImg = image;
  isValidImageUrl(image, (isValid) => {
    if (isValid) {
      avatarImg = image;
    } else {
      avatarImg = "";
    }
  });

  return {
    sx: {
      bgcolor: avatarImg ? "transparent" : bgcolor,
      color: color,
      width: pxToRem(size),
      height: pxToRem(size),
      fontSize: pxToRem(font),
      ...sx,
    },
    src: avatarImg,
    children: `${(name?.split(" ")[0][0] || "").toUpperCase()}${(
      name?.split(" ")[name?.split(" ").length > 1 ? 1 : 0][
        name?.split(" ").length > 1 ? 0 : 1
      ] || ""
    ).toUpperCase()}`,
  };
};

export const removeHtmlTags = (inputString) => {
  return inputString.replace(/<[^>]*>/g, "");
};

export const getLocalStorage = (val = "") => {
  const value = localStorage.getItem(val);
  return value;
};

export const appendZero = (number) => {
  if (number < 10) {
    return `0${+number}`;
  }
  return number;
};

export const ResetScroll = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    const html = document.documentElement;
    window.scrollTo(0, 0);
    html.style.scrollBehavior = "unset";
    return () => {
      html.style.scrollBehavior = "smooth";
    };
  }, [pathname]);
  return null;
};

export function setCurrentTime() {
  const currentTime = dayjs();
  return currentTime;
}

function formatTime(milliseconds) {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return [minutes, remainingSeconds];
}

export function startCountdown() {
  const currentTime = localStorage.getItem("sendOTPtime");
  if (!currentTime) {
    return [0, 0];
  }
  const targetTime = dayjs(currentTime).add(4, "minute").add(59, "seconds");
  const now = dayjs();
  const remainingTime = targetTime.diff(now);

  if (remainingTime <= 0) {
    return [0, 0];
  } else {
    return formatTime(remainingTime);
  }
}

export const uploadFileToS3 = async (file, type, date) => {
  try {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_S3_REGION,
    });

    const name = file.name.split(" ").join("_");
    const fileName = type + date + name;
    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: fileName.toLowerCase(),
      Body: file,
      ContentType: file.type,
      ACL: "public-read",
    };

    return [params, s3];
  } catch (error) {
    console.error("Error initializing S3:", error);
  }
};

export const capitalize = (word) => {
  return word
    ?.toLowerCase()
    ?.split(" ")
    ?.map((text) => {
      if (!text) return "";
      return text[0].toUpperCase() + text.substring(1);
    })
    ?.join(" ");
};

export const getNewObjectProperties = (prevObj, newObj) => {
  const results = prevObj.filter((obj) => {
    return !newObj.some((obj2) => {
      return JSON.stringify(obj) === JSON.stringify(obj2);
    });
  });
  return results;
};

export const getNewProperties = (prevObj, newObj) => {
  const resultObject = {};
  for (const key in prevObj) {
    if (
      !dayjs(prevObj[key]).isSame(newObj[key]) &&
      prevObj[key] !== newObj[key]
    ) {
      if (newObj[key]?.length > 0 || !isNaN(newObj[key]) || newObj[key]) {
        resultObject[key] = newObj[key];
      } else {
        resultObject[key] = null;
      }
    }
  }
  if (resultObject["phone_number"] || resultObject["iso_country_code"]) {
    resultObject["iso_country_code"] = newObj.iso_country_code;
    resultObject["country_code"] = newObj.country_code;
    resultObject["phone_number"] = newObj.phone_number;
  }
  if (
    resultObject["emergency_phone_number"] ||
    resultObject["iso_emergency_country_code"]
  ) {
    resultObject["iso_emergency_country_code"] =
      newObj.iso_emergency_country_code;
    resultObject["emergency_country_code"] = newObj.emergency_country_code;
    resultObject["emergency_phone_number"] = newObj.emergency_phone_number;
  }
  return resultObject;
};

export function removeEmptyValues(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (
      value !== "" && // Check for empty string
      value !== null && // Check for null
      value !== undefined && // Check for undefined
      !(typeof value === "number" && isNaN(value)) && // Check for NaN
      !(Array.isArray(value) && value.length === 0) && // Check for empty array
      !(typeof value === "object" && !Array.isArray(value) && Object.keys(value).length === 0) // Check for empty object
    ) {
      acc[key] = value;
    }
    return acc;
  }, {});
}

export const checkIfEmpty = (value) => {
  if (value && value.length > 0) {
    return value;
  }
  return null;
};

export const getValueByKey = (arr, searchValue, compareKey, returnKey) => {
  const foundItem = arr?.find((item) => item[compareKey] == searchValue);

  if (foundItem) {
    return foundItem[returnKey];
  } else {
    return null;
  }
};

export const commonErrorShowFn = (error, suiSnackbar, isOffline) => {
  if (error) {
    if (error?.originalStatus >= 500 && error?.originalStatus !== 503) {
      suiSnackbar("Internal server error!");
    } else if (error?.originalStatus === 503) {
      suiSnackbar("Service Unavailable!");
    } else if (error?.data) {
      suiSnackbar(error?.data.message);
    } else {
      suiSnackbar(
        isOffline
          ? "Check your Internet connection and try again!"
          : "Something went wrong!"
      );
    }
  }
};

export const convertTimeFormet = (time, add = null) => {
  const [hour, minute] = time.split(":");
  if (add) {
    const added = +hour + +add;
    const addHour = added < 24 ? +hour + add : added - 24;
    const newHour =
      addHour <= 12 ? appendZero(addHour) : appendZero(addHour - 12);
    const getA = addHour < 12 ? "AM" : "PM";
    const newTime = `${newHour == 0 ? 12 : newHour}:${minute} ${getA}`;
    return newTime;
  } else {
    const newHour = hour <= 12 ? appendZero(hour) : appendZero(hour - 12);
    const getA = hour < 12 ? "AM" : "PM";
    const newTime = `${newHour == 0 ? 12 : newHour}:${minute} ${getA}`;
    return newTime;
  }
};

export function setCookie(name, value, days) {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
}

export function getCookie(name) {
  const cookieName = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return "";
}

export function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function clearAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
}

export const selectedDatesFn = (data, dateKey, venueId, sportType) => {
  const dates = data?.filter((item) => {
    return item.courtDetails.venue_id === venueId && item.courtDetails.sport_type === sportType;
  }).map((item) => moment.utc(item[dateKey]).format("YYYY-MM-DD"));

  const uniqueDatesSet = new Set(dates);

  const uniqueDatesArray = [...uniqueDatesSet];

  return uniqueDatesArray;
};

export const selectedCortsFn = (data, dateKey, bookingDate) => {
  if (moment(bookingDate).isValid()) {
    const b = moment(bookingDate).format('YYYY-MM-DD');
    const courts = data?.filter((item) => {
      return moment.utc(item[dateKey]).isSame(new Date(b));
    }).map((item) => item?.court_id);
    return courts;
  }

  return [];
};

export const selectedSlotFn = (data, dateKey, bookingDate, cortId) => {
  if (moment(bookingDate).isValid() && cortId) {
    const b = moment(bookingDate).format('YYYY-MM-DD');
    const slots = data?.filter((item) => {
      return moment.utc(item[dateKey]).isSame(new Date(b)) && item.court_id === cortId;
    }).map((item) => item?.slot_id);
    return slots;
  }

  return [];
};

export const calculateAge = (dob) => {
  if (!dob) return 0; // Return 0 if dob is not provided
  const birthDate = dayjs(dob);
  const today = dayjs();

  // Calculate age in years
  const age = today.diff(birthDate, "year");
  return age;
};

export const getTimeAsDate = (time) => {
  if (!time) return null;
  if(!dayjs(time).isValid()) {
    return dayjs(`1970-01-01T${time}`, "YYYY-MM-DD HH:mm");
  } else {
    return time;
  }
};

export const getDuration = (stime,etime) => {
  // Parse the times using dayjs
  const start = getTimeAsDate(stime); // adding a date for accurate parsing
  const end = getTimeAsDate(etime);

  // Calculate the difference in minutes
  const diffInMinutes = end.diff(start, 'minute');
  // Convert minutes to hours and minutes
  const duration = dayjs.duration(diffInMinutes, 'minutes');
  const hours = duration.hours();
  const minutes = duration.minutes();

  // Format the output
  const formattedDuration = `${hours}Hr ${minutes}Min`;

  return formattedDuration;
}

export const inlineExpFn = (bool, val1, val2) => {
  return bool ? val1 : val2;
} 


export const arraysAreEqual = (arr1, arr2) => {
  // Check if lengths are different
  if (arr1.length !== arr2.length) return false;

  // Compare each element
  for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
  }

  return true;
}

export const arraysAreEqualUnordered = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;

  // Sort and compare
  const sorted1 = [...arr1].sort();
  const sorted2 = [...arr2].sort();

  for (let i = 0; i < sorted1.length; i++) {
      if (sorted1[i] !== sorted2[i]) return false;
  }

  return true;
}

export function removeEmptyKeys(obj) {
  return Object.fromEntries(
      Object.entries(obj).filter((item) => item[1] !== "" && item[1] !== undefined)
  );
}

export const calculateReadingTime = (htmlContent) => {
  const wordsPerMinute = 200; // Average reading speed
  
  // Remove HTML tags and decode HTML entities if necessary
  const text = htmlContent.replace(/<[^>]*>/g, " ").trim();
  
  const words = text.split(/\s+/).length; // Count words
  const time = Math.max(1, Math.ceil(words / wordsPerMinute)); // Ensure minimum 1 minute
  
  return time;
};
