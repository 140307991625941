/* eslint-disable no-undef */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Logout } from '../authSlice';
import { setIsOffline } from '../uiCtrlSlice';
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers) => {
    headers.set('Content-Type', 'application/json');
    const token = localStorage.getItem("auth_token");
    if (token) {
      headers.set("authtoken", token);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (window.navigator.onLine) {
    api.dispatch(setIsOffline(false));
  } else {
    api.dispatch(setIsOffline(true));
  }
  if ([403].includes(result?.error?.status)) {
    api.dispatch(Logout());
    window.location.href = '/';
  }
  return result;
};

export const baseAPIService = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["COMMON_GET", "GET_PROFILE", "GET_VENUE", 'GET_BOOKING', 'GET_COURT', 'GET_COURT_SLOT', 'GET_PLANS', 'GET_CART', 'GET_CON_CART', 'GET_TRAINEE','GET_TRAINING', 'GET_TRAINING_CATEGORIES', 'GET_TRAINING_SLOTS', 'GET_TRAINING_BOOKINGS', 'GET_BLOGS', 'GET_BLOG_DETAILS'],
  endpoints: () => ({}),
});