// import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import ReactDOM from 'react-dom/client';
import 'index.css';
import App from 'App';
import theme from 'theme';
import { Provider } from 'react-redux';
import store from 'app/store';
import { SnackbarProvider } from 'notistack';
import { AOSInit } from 'common/ASOInit';
import SessionDilog from 'components/session-dialog';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AOSInit />
      <SessionDilog />
      <Provider store={store}>
        <SnackbarProvider
          maxSnack={4}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          preventDuplicate
        >
          <App />
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>
);
